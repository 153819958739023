

























import { Component, Vue } from 'vue-property-decorator'
import { DropDownListPlugin } from "@syncfusion/ej2-vue-dropdowns";
import { MyGridOption } from '@/Helper/MyDataAdapter';
import { Enums } from '@/Helper/Enum';
Vue.use(DropDownListPlugin);
@Component({
    components: {
  }
})
export default class AddCourse extends Vue {
 
   data:any={};
   Teachers = MyGridOption.getDataManager('TeacherAdminList');
   Sessions = MyGridOption.getDataManager('SessionAdminList');
   Language = Enums.LanguageType;
 
}
